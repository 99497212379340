var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        {
          staticClass: "p-0",
          class: {
            "container-layout": _vm.$screen.width >= 992,
            "container-mobile": _vm.$screen.width < 992,
          },
        },
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              loader: "bars",
              "is-full-page": "",
              color: "#00A09C",
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _vm.$screen.width <= 576
            ? _c("div", { staticClass: "animated fadeIn" }, [
                _vm.currentExpenseMobile
                  ? _c("div", { staticClass: "container-mobile" }, [
                      _c("div", { staticClass: "my-expense-mobile" }, [
                        _c("div", { staticClass: "title" }, [
                          _c(
                            "h1",
                            {
                              staticClass: "main-page-title",
                              class: { "is-pwa": _vm.$isPwa() },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(7, "Expense details")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "back-with-title cursor-pointer",
                          class: { "mt-16": _vm.$isPwa() },
                          on: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "icon" },
                            [
                              _c("arrow-left", {
                                attrs: { color: "#667987", size: 22 },
                              }),
                            ],
                            1
                          ),
                          _c("h2", [
                            _vm._v("#" + _vm._s(_vm.currentExpenseMobile.id)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "card has-margin-bottom mt-16" },
                        [
                          _c("div", { staticClass: "wrapper" }, [
                            _c("div", { staticClass: "header" }, [
                              _c("div", { staticClass: "first-col" }, [
                                _c("span", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.dateToLiteral(
                                          _vm.currentExpenseMobile.date
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]),
                              !_vm.isFilmSingle
                                ? _c("div", { staticClass: "second-col" }, [
                                    _c(
                                      "div",
                                      {
                                        class: `status ${_vm.currentExpenseMobile.statusClass}`,
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.currentExpenseMobile
                                                  .validatedStatus
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "content" }, [
                              _vm.showExpenseTitle(_vm.currentExpenseMobile)
                                ? _c(
                                    "div",
                                    { staticClass: "expense-title mt-3" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.currentExpenseMobile.description
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.currentExpenseMobile.comment &&
                              _vm.currentExpenseMobile.comment.length > 0
                                ? _c("div", { staticClass: "comment mt-3" }, [
                                    _c(
                                      "div",
                                      { staticClass: "pl-2" },
                                      [
                                        _c("alert-circle", {
                                          attrs: { size: "20" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "px-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.currentExpenseMobile.comment)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row-cols mt-3" }, [
                                _c("div", { staticClass: "third-col" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentExpenseMobile.typeString
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "title" }, [
                                    _c(
                                      "span",
                                      [
                                        _vm.currentExpenseMobile.type === 0
                                          ? _c("ticket", {
                                              attrs: {
                                                color: "#5B6164D6",
                                                size: 16,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.currentExpenseMobile.type === 1
                                          ? _c("file-text", {
                                              attrs: {
                                                color: "#5B6164D6",
                                                size: 14,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.currentExpenseMobile.type === 2
                                          ? _c("car", {
                                              attrs: {
                                                color: "#5B6164D6",
                                                size: 16,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "first-col" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.convertToCurrency(
                                          _vm.currentExpenseMobile.amountTotal
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "title" }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(8, "Total amount"))
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "second-col" }, [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.convertToCo2(
                                          _vm.currentExpenseMobile.kgCoTwo
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("span", { staticClass: "title" }, [
                                    _c(
                                      "div",
                                      { staticClass: "hack-top-3" },
                                      [
                                        _c("sprout", {
                                          attrs: { color: "#47C7BF", size: 12 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ml-1" }, [
                                      _vm._v("CO"),
                                      _c("sub", [_vm._v("2")]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "expense-detail-actions pt-2" },
                            [
                              !_vm.isFilmSingle
                                ? _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "mr-2" },
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            attrs: {
                                              id: "dropExpDetails",
                                              variant: "primary",
                                              lazy: "",
                                              disabled:
                                                _vm.isLoadingDownload ||
                                                _vm.isLoadingSendMail,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _vm.isLoadingDownload ||
                                                      _vm.isLoadingSendMail
                                                        ? _c("b-spinner", {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              854,
                                                              "Simple expense sheet"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1443618990
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: { click: _vm.downloadXls },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "Download"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        staticClass: "mr-2",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            221,
                                                            "Download this Expense"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: _vm.sendXlsByEmail,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon("Mail"),
                                                      {
                                                        tag: "component",
                                                        staticClass: "mr-2",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            5,
                                                            "Send me this Expense by Email"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.currentExpenseMobile.validated === 8 &&
                                    _vm.enableCertified
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-dropdown",
                                              {
                                                attrs: {
                                                  id: "dropExpDetails",
                                                  variant:
                                                    "custom-primary-blue",
                                                  lazy: "",
                                                  disabled:
                                                    _vm.isLoadingCertifiedDownload ||
                                                    _vm.isLoadingCertifiedSendMail,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "button-content",
                                                      fn: function () {
                                                        return [
                                                          _vm.isLoadingCertifiedDownload
                                                            ? _c("b-spinner", {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  2854,
                                                                  "Certified digital expense sheet"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  550511873
                                                ),
                                              },
                                              [
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadCertified(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Download"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "mr-2",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                2855,
                                                                "Download"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-dropdown-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadCertified(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Mail"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            staticClass: "mr-2",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                2856,
                                                                "Send me by email"
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "expense-detail-download",
                                      },
                                      [
                                        _c("share-information", {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            "btn-label": _vm.FormMSG(
                                              59,
                                              "Share invoice"
                                            ),
                                            "title-modal": _vm.FormMSG(
                                              60,
                                              "Share the invoice expense to :"
                                            ),
                                            "send-a-copy": true,
                                            "current-expense":
                                              _vm.currentExpenseMobile,
                                            "is-send-invoice": true,
                                            "name-modal":
                                              "modal-share-invoice-expenses",
                                            icon: "fa fa-file-text",
                                            "variant-btn": "warning",
                                            isDisabled: !_vm.isFilmSinglePaying,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "expense-detail-send-me pl-3",
                                      },
                                      [
                                        _c("share-information", {
                                          attrs: {
                                            "btn-label": _vm.FormMSG(
                                              169,
                                              "Share"
                                            ),
                                            "title-modal": _vm.FormMSG(
                                              170,
                                              "Send this expense sheet to :"
                                            ),
                                            "send-a-copy": true,
                                            "current-expense":
                                              _vm.currentExpenseMobile,
                                            "name-modal":
                                              "modal-share-expenses",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                            ]
                          ),
                          _vm.toggleDetail
                            ? _c(
                                "div",
                                { staticClass: "card-inside-card" },
                                [
                                  _c("card-list-builder", {
                                    attrs: {
                                      "use-new-version": true,
                                      items: _vm.ExpItems,
                                      fields: _vm.typeMobileFields,
                                      "header-fields": _vm.ticketMobileFields,
                                      "has-badge": true,
                                      "is-badge-use-props": true,
                                      "badge-class": "statusClass",
                                      "badge-value": "validatedStatus",
                                      "hide-status": _vm.isFilmSingle,
                                      "has-initial-data":
                                        _vm.ExpItems && _vm.ExpItems.length > 0,
                                      "key-for-title": "description",
                                      "toggle-mode": false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function ({ item }) {
                                            return [
                                              item.images &&
                                              item.images.length > 0
                                                ? _c("photo", {
                                                    staticClass:
                                                      "action cursor-pointer",
                                                    attrs: { size: 20 },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showPicture(
                                                          item.images,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.canReadComment
                                                ? _c("message-circle", {
                                                    staticClass:
                                                      "action cursor-pointer",
                                                    attrs: {
                                                      size: 20,
                                                      color: "#EA4E2C",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editComment(
                                                          item.comment
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showEditExpenseMobile(item)
                                                ? _c("edit", {
                                                    staticClass:
                                                      "action cursor-pointer",
                                                    attrs: { size: 18 },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editExpenseMobile(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.canDeleteExpensesOrPO
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "btn bg-transparent border-0",
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openDeleteWarning(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Trash"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass:
                                                            "text-danger",
                                                          attrs: { size: 20 },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2300260330
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c(
                "div",
                { staticClass: "animated fadeIn form" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "12" } }, [
                        _c(
                          "h1",
                          {
                            class: [
                              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                              { "is-pwa": _vm.$isPwa() },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.FormMSG(7, "Expense details")))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.ExpItems && _vm.ExpItems.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "mt-0" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticClass: "card-border-blue-light",
                                      attrs: { "no-body": "" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0",
                                          class: { "mt-16": _vm.$isPwa() },
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-transparent",
                                                  on: {
                                                    click: _vm.backToExpense,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "ArrowLeft"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass: "icon",
                                                      attrs: {
                                                        color:
                                                          "rgba(6, 38, 62, 0.65)",
                                                        size: 22,
                                                      },
                                                    }
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "title-back-bolder mx-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.headerCaption
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "header-title-exp-detail text-right",
                                                  staticStyle: {
                                                    border: "none",
                                                  },
                                                },
                                                [
                                                  !_vm.isFilmSingle
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "header-date-title pr-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "wrap-status d-flex justify-content-end",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  class: `status ${_vm.curExp.statusClass}`,
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "0.825rem",
                                                                    border:
                                                                      "0.005rem solid rgba(226, 230, 235, 0.65)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .curExp
                                                                          .validatedStatus
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "h3",
                                                        { staticClass: "my-0" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_vm.caption1)
                                                          ),
                                                        ]
                                                      ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center justify-content-end w-100 pr-3",
                                                    },
                                                    [
                                                      !_vm.isFilmSingle
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "border-encoded p-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    149,
                                                                    "Encoded by: "
                                                                  )
                                                                ) + " "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.encodedBy(
                                                                      _vm.curExp
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "header-name-title pr-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.getDateFormated(
                                                              _vm.curExp.date
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("span", {
                                                        staticClass:
                                                          "divider-dot",
                                                      }),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.curExp
                                                              .amountTotal +
                                                              " " +
                                                              _vm.getCurrency
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-card-text",
                                        { staticClass: "pt-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "px-1" },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        xl: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card border-0 p-3",
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-end",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: `${
                                                                      _vm.curExp
                                                                        .comment
                                                                        .length >
                                                                      0
                                                                        ? 4
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            645654005,
                                                                            "Description"
                                                                          ),
                                                                        "label-for":
                                                                          "description",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-textarea",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                "",
                                                                              value:
                                                                                _vm
                                                                                  .curExp
                                                                                  .description,
                                                                              type: "text",
                                                                              id: "description",
                                                                              rows: "3",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.curExp.comment
                                                                .length > 0
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "4",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  4,
                                                                                  "Comment"
                                                                                ),
                                                                              "label-for":
                                                                                "comment",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-textarea",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  disabled: true,
                                                                                  id: "comment",
                                                                                  rows: "3",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .curExp
                                                                                      .comment,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.curExp,
                                                                                        "comment",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "curExp.comment",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    sm: `${
                                                                      _vm.curExp
                                                                        .comment
                                                                        .length >
                                                                      0
                                                                        ? 4
                                                                        : 6
                                                                    }`,
                                                                  },
                                                                },
                                                                [
                                                                  _vm.$screen
                                                                    .width >=
                                                                    992 &&
                                                                  (_vm.isFilmSingle ||
                                                                    _vm.isProd)
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex flex-row align-items-center justify-content-end",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-button",
                                                                            {
                                                                              staticClass:
                                                                                "w-170-px d-flex justify-content-center align-items-center",
                                                                              attrs:
                                                                                {
                                                                                  size: "md",
                                                                                  variant:
                                                                                    "blue-sapphire",
                                                                                  block:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.reviewMode,
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-spinner",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          _vm.watingSubmitReviewMode,
                                                                                        expression:
                                                                                          "watingSubmitReviewMode",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              !_vm.watingSubmitReviewMode
                                                                                ? _c(
                                                                                    _vm.getLucideIcon(
                                                                                      "View"
                                                                                    ),
                                                                                    {
                                                                                      tag: "component",
                                                                                      staticClass:
                                                                                        "mr-2",
                                                                                      attrs:
                                                                                        {
                                                                                          size: 20,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-top":
                                                                                        "2px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        2857,
                                                                                        "Review mode"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "mt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "hide-on-desktop",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    xl: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.curExp
                                                                    .type < 2
                                                                    ? _c(
                                                                        "CardListBuilder",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              items:
                                                                                _vm.ExpItems,
                                                                              "style-mode":
                                                                                "table",
                                                                              fields:
                                                                                _vm.ticketMobileFields,
                                                                              "collapsed-fields":
                                                                                _vm.ticketCollapseMobileFields,
                                                                              "hide-status":
                                                                                _vm.isFilmSingle,
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "actions",
                                                                                  fn: function ({
                                                                                    item,
                                                                                    showCollapseDetails,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "b-button",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              variant:
                                                                                                "primary",
                                                                                              size: "sm",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return showCollapseDetails(
                                                                                                  item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "i",
                                                                                            {
                                                                                              staticClass:
                                                                                                "icon-eye",
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      item
                                                                                        .images
                                                                                        .length >
                                                                                      0
                                                                                        ? _c(
                                                                                            "b-button",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn bg-transparent border-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  variant:
                                                                                                    "success",
                                                                                                  size: "sm",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.showPicture(
                                                                                                      item.images,
                                                                                                      item
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                _vm.getLucideIcon(
                                                                                                  _vm
                                                                                                    .ICONS
                                                                                                    .PICTURE
                                                                                                    .name
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      color:
                                                                                                        _vm
                                                                                                          .ICONS
                                                                                                          .PICTURE
                                                                                                          .color,
                                                                                                      size: 20,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm.canDeleteExpensesOrPO
                                                                                        ? _c(
                                                                                            "b-button",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn bg-transparent border-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  variant:
                                                                                                    "danger",
                                                                                                  size: "sm",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.openDeleteWarning(
                                                                                                      item
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                _vm.getLucideIcon(
                                                                                                  "Trash"
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  staticClass:
                                                                                                    "text-danger",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      size: 20,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              1763135958
                                                                            ),
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "hide-on-tablet",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    xl: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.curExp
                                                                    .type < 2
                                                                    ? _c(
                                                                        "b-table",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "left",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              hover:
                                                                                _vm.hover,
                                                                              responsive:
                                                                                "",
                                                                              selectable:
                                                                                "",
                                                                              selectedVariant:
                                                                                _vm.selectedColor,
                                                                              "select-mode":
                                                                                _vm.selectMode,
                                                                              items:
                                                                                _vm.ExpItems,
                                                                              fields:
                                                                                _vm.ticketFields,
                                                                              "current-page":
                                                                                _vm.currentPage,
                                                                              "sticky-header":
                                                                                "800px",
                                                                              "per-page":
                                                                                _vm.perPage,
                                                                              bordered:
                                                                                "",
                                                                              small:
                                                                                "",
                                                                              "head-variant":
                                                                                _vm.hv,
                                                                            },
                                                                          on: {
                                                                            "row-clicked":
                                                                              _vm.rowClicked,
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "cell(validated)",
                                                                                  fn: function (
                                                                                    data
                                                                                  ) {
                                                                                    return [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "wrap-status",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              class: `status ${data.item.statusClass}`,
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "0.7rem",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                  _vm._s(
                                                                                                    data
                                                                                                      .item
                                                                                                      .validatedStatus
                                                                                                  ) +
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                                {
                                                                                  key: "cell(option)",
                                                                                  fn: function (
                                                                                    data
                                                                                  ) {
                                                                                    return [
                                                                                      data
                                                                                        .item
                                                                                        .images
                                                                                        .length >
                                                                                        0 ||
                                                                                      _vm.canDeleteExpensesOrPO
                                                                                        ? _c(
                                                                                            "b-dropdown",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  "no-caret":
                                                                                                    "",
                                                                                                  dropleft:
                                                                                                    "",
                                                                                                  offset:
                                                                                                    "25",
                                                                                                  variant:
                                                                                                    "none",
                                                                                                  size: "sm",
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "button-content",
                                                                                                      fn: function () {
                                                                                                        return [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "MoreVertical"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                      proxy: true,
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                ),
                                                                                            },
                                                                                            [
                                                                                              _vm.canDeleteExpensesOrPO
                                                                                                ? _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.openDeleteWarning(
                                                                                                              data.item
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-danger d-flex align-items-center",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Trash"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-2",
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm.FormMSG(
                                                                                                                    1002,
                                                                                                                    "Delete"
                                                                                                                  )
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              3583193416
                                                                            ),
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "hide-on-desktop",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    xl: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.curExp
                                                                    .type === 2
                                                                    ? _c(
                                                                        "CardListBuilder",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              items:
                                                                                _vm.ExpItems,
                                                                              fields:
                                                                                _vm.kmFields,
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "actions",
                                                                                  fn: function ({
                                                                                    item,
                                                                                  }) {
                                                                                    return [
                                                                                      _vm.canDeleteExpensesOrPO
                                                                                        ? _c(
                                                                                            "b-button",
                                                                                            {
                                                                                              staticClass:
                                                                                                "btn bg-transparent border-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  variant:
                                                                                                    "danger",
                                                                                                  size: "sm",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.openDeleteWarning(
                                                                                                      item
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                _vm.getLucideIcon(
                                                                                                  "Trash"
                                                                                                ),
                                                                                                {
                                                                                                  tag: "component",
                                                                                                  staticClass:
                                                                                                    "text-danger",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      size: 20,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              3405092640
                                                                            ),
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "hide-on-tablet",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    xl: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.curExp
                                                                    .type === 2
                                                                    ? _c(
                                                                        "b-table",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "left",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              hover:
                                                                                _vm.hover,
                                                                              responsive:
                                                                                "",
                                                                              selectable:
                                                                                "",
                                                                              selectedVariant:
                                                                                _vm.selectedColor,
                                                                              "select-mode":
                                                                                _vm.selectMode,
                                                                              items:
                                                                                _vm.ExpItems,
                                                                              fields:
                                                                                _vm.kmFields,
                                                                              "current-page":
                                                                                _vm.currentPage,
                                                                              "per-page":
                                                                                _vm.perPage,
                                                                              bordered:
                                                                                "",
                                                                              small:
                                                                                "",
                                                                              "head-variant":
                                                                                _vm.hv,
                                                                            },
                                                                          on: {
                                                                            "row-clicked":
                                                                              _vm.rowClicked,
                                                                          },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "cell(validatedStatus)",
                                                                                  fn: function (
                                                                                    data
                                                                                  ) {
                                                                                    return [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "wrap-status",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              class: `status ${data.item.statusClass}`,
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "0.7rem",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                  _vm._s(
                                                                                                    data
                                                                                                      .item
                                                                                                      .validatedStatus
                                                                                                  ) +
                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                                {
                                                                                  key: "cell(option)",
                                                                                  fn: function (
                                                                                    data
                                                                                  ) {
                                                                                    return [
                                                                                      data
                                                                                        .item
                                                                                        .images
                                                                                        .length >
                                                                                        0 ||
                                                                                      _vm.canDeleteExpensesOrPO
                                                                                        ? _c(
                                                                                            "b-dropdown",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  "no-caret":
                                                                                                    "",
                                                                                                  dropleft:
                                                                                                    "",
                                                                                                  offset:
                                                                                                    "25",
                                                                                                  variant:
                                                                                                    "none",
                                                                                                  size: "sm",
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "button-content",
                                                                                                      fn: function () {
                                                                                                        return [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "MoreVertical"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                      proxy: true,
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                ),
                                                                                            },
                                                                                            [
                                                                                              _vm.canDeleteExpensesOrPO
                                                                                                ? _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.openDeleteWarning(
                                                                                                              data.item
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-danger d-flex align-items-center",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Trash"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-2",
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm.FormMSG(
                                                                                                                    1002,
                                                                                                                    "Delete"
                                                                                                                  )
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              false,
                                                                              1861435804
                                                                            ),
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          !_vm.isFilmSingle
                                                            ? _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-row",
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .$screen
                                                                                .width >=
                                                                              992
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-2",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-dropdown",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  id: "dropExpDetails",
                                                                                                  variant:
                                                                                                    "primary",
                                                                                                  lazy: "",
                                                                                                  disabled:
                                                                                                    _vm.isLoadingDownload ||
                                                                                                    _vm.isLoadingSendMail,
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "button-content",
                                                                                                      fn: function () {
                                                                                                        return [
                                                                                                          _vm.isLoadingDownload ||
                                                                                                          _vm.isLoadingSendMail
                                                                                                            ? _c(
                                                                                                                "b-spinner",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  854,
                                                                                                                  "Simple expense sheet"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                      proxy: true,
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  false,
                                                                                                  2306061230
                                                                                                ),
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b-dropdown-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.downloadXls,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        _vm.getLucideIcon(
                                                                                                          "Download"
                                                                                                        ),
                                                                                                        {
                                                                                                          tag: "component",
                                                                                                          staticClass:
                                                                                                            "mr-2",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: 16,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                          _vm._s(
                                                                                                            _vm.FormMSG(
                                                                                                              221,
                                                                                                              "Download this Expense"
                                                                                                            )
                                                                                                          ) +
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "b-dropdown-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.sendXlsByEmail,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        _vm.getLucideIcon(
                                                                                                          "Mail"
                                                                                                        ),
                                                                                                        {
                                                                                                          tag: "component",
                                                                                                          staticClass:
                                                                                                            "mr-2",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: 16,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                          _vm._s(
                                                                                                            _vm.FormMSG(
                                                                                                              5,
                                                                                                              "Send me this Expense by Email"
                                                                                                            )
                                                                                                          ) +
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _vm
                                                                                        .curExp
                                                                                        .validated ===
                                                                                        8 &&
                                                                                      _vm.enableCertified
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "b-dropdown",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      id: "dropExpDetails",
                                                                                                      variant:
                                                                                                        "custom-primary-blue",
                                                                                                      lazy: "",
                                                                                                      disabled:
                                                                                                        _vm.isLoadingCertifiedDownload ||
                                                                                                        _vm.isLoadingCertifiedSendMail,
                                                                                                    },
                                                                                                  scopedSlots:
                                                                                                    _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key: "button-content",
                                                                                                          fn: function () {
                                                                                                            return [
                                                                                                              _vm.isLoadingCertifiedDownload
                                                                                                                ? _c(
                                                                                                                    "b-spinner",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          small:
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    }
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _vm._v(
                                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                                  _vm._s(
                                                                                                                    _vm.FormMSG(
                                                                                                                      2854,
                                                                                                                      "Certified digital expense sheet"
                                                                                                                    )
                                                                                                                  ) +
                                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                              ),
                                                                                                            ]
                                                                                                          },
                                                                                                          proxy: true,
                                                                                                        },
                                                                                                      ],
                                                                                                      null,
                                                                                                      false,
                                                                                                      4247950593
                                                                                                    ),
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.downloadCertified(
                                                                                                              false
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Download"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              staticClass:
                                                                                                                "mr-2",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  2855,
                                                                                                                  "Download"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.downloadCertified(
                                                                                                              true
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Mail"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              staticClass:
                                                                                                                "mr-2",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  2856,
                                                                                                                  "Send me by email"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .$screen
                                                                                .width <
                                                                              992
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-2",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b-dropdown",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  id: "dropExpDetails",
                                                                                                  variant:
                                                                                                    "primary",
                                                                                                  lazy: "",
                                                                                                  disabled:
                                                                                                    _vm.isLoadingDownload ||
                                                                                                    _vm.isLoadingSendMail,
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "button-content",
                                                                                                      fn: function () {
                                                                                                        return [
                                                                                                          _vm.isLoadingDownload ||
                                                                                                          _vm.isLoadingSendMail
                                                                                                            ? _c(
                                                                                                                "b-spinner",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  854,
                                                                                                                  "Simple expense sheet"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                      proxy: true,
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  false,
                                                                                                  2306061230
                                                                                                ),
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b-dropdown-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.downloadXls,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        _vm.getLucideIcon(
                                                                                                          "Download"
                                                                                                        ),
                                                                                                        {
                                                                                                          tag: "component",
                                                                                                          staticClass:
                                                                                                            "mr-2",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: 16,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                          _vm._s(
                                                                                                            _vm.FormMSG(
                                                                                                              221,
                                                                                                              "Download this Expense"
                                                                                                            )
                                                                                                          ) +
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "b-dropdown-item",
                                                                                                {
                                                                                                  on: {
                                                                                                    click:
                                                                                                      _vm.sendXlsByEmail,
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        _vm.getLucideIcon(
                                                                                                          "Mail"
                                                                                                        ),
                                                                                                        {
                                                                                                          tag: "component",
                                                                                                          staticClass:
                                                                                                            "mr-2",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              size: 16,
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                      _vm._v(
                                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                          _vm._s(
                                                                                                            _vm.FormMSG(
                                                                                                              5,
                                                                                                              "Send me this Expense by Email"
                                                                                                            )
                                                                                                          ) +
                                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _vm
                                                                                        .curExp
                                                                                        .validated ===
                                                                                        8 &&
                                                                                      _vm.enableCertified
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                "b-dropdown",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      id: "dropExpDetails",
                                                                                                      variant:
                                                                                                        "custom-primary-blue",
                                                                                                      lazy: "",
                                                                                                      disabled:
                                                                                                        _vm.isLoadingCertifiedDownload ||
                                                                                                        _vm.isLoadingCertifiedSendMail,
                                                                                                    },
                                                                                                  scopedSlots:
                                                                                                    _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key: "button-content",
                                                                                                          fn: function () {
                                                                                                            return [
                                                                                                              _vm.isLoadingCertifiedDownload
                                                                                                                ? _c(
                                                                                                                    "b-spinner",
                                                                                                                    {
                                                                                                                      attrs:
                                                                                                                        {
                                                                                                                          small:
                                                                                                                            "",
                                                                                                                        },
                                                                                                                    }
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _vm._v(
                                                                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                                  _vm._s(
                                                                                                                    _vm.FormMSG(
                                                                                                                      2854,
                                                                                                                      "Certified digital expense sheet"
                                                                                                                    )
                                                                                                                  ) +
                                                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                              ),
                                                                                                            ]
                                                                                                          },
                                                                                                          proxy: true,
                                                                                                        },
                                                                                                      ],
                                                                                                      null,
                                                                                                      false,
                                                                                                      4247950593
                                                                                                    ),
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.downloadCertified(
                                                                                                              false
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Download"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              staticClass:
                                                                                                                "mr-2",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  2855,
                                                                                                                  "Download"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "b-dropdown-item",
                                                                                                    {
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.downloadCertified(
                                                                                                              true
                                                                                                            )
                                                                                                          },
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            _vm.getLucideIcon(
                                                                                                              "Mail"
                                                                                                            ),
                                                                                                            {
                                                                                                              tag: "component",
                                                                                                              staticClass:
                                                                                                                "mr-2",
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: 16,
                                                                                                                },
                                                                                                            }
                                                                                                          ),
                                                                                                          _vm._v(
                                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                                              _vm._s(
                                                                                                                _vm.FormMSG(
                                                                                                                  2856,
                                                                                                                  "Send me by email"
                                                                                                                )
                                                                                                              ) +
                                                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  staticClass:
                                                                    "clearfix",
                                                                  attrs: {
                                                                    cols: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "float-right",
                                                                    },
                                                                    [
                                                                      _vm.isFilmSingle
                                                                        ? _c(
                                                                            "share-information",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-right":
                                                                                    "5px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  "btn-label":
                                                                                    _vm.FormMSG(
                                                                                      59,
                                                                                      "Share invoice"
                                                                                    ),
                                                                                  "title-modal":
                                                                                    _vm.FormMSG(
                                                                                      60,
                                                                                      "Share the invoice expense to :"
                                                                                    ),
                                                                                  "send-a-copy": true,
                                                                                  "current-expense":
                                                                                    _vm.curExp,
                                                                                  "is-send-invoice": true,
                                                                                  "name-modal":
                                                                                    "modal-share-invoice-expenses",
                                                                                  icon: "fa fa-file-text",
                                                                                  "variant-btn":
                                                                                    "warning",
                                                                                  isDisabled:
                                                                                    !_vm.isFilmSinglePaying,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.isFilmSingle
                                                                        ? _c(
                                                                            "share-information",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "btn-label":
                                                                                    _vm.FormMSG(
                                                                                      169,
                                                                                      "Share"
                                                                                    ),
                                                                                  "title-modal":
                                                                                    _vm.FormMSG(
                                                                                      170,
                                                                                      "Send this expense sheet to :"
                                                                                    ),
                                                                                  "send-a-copy": true,
                                                                                  "current-expense":
                                                                                    _vm.curExp,
                                                                                  "name-modal":
                                                                                    "modal-share-expenses",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c("expense-carousel-view-modal", {
            ref: "expense-carousel-view-modal",
            attrs: {
              expense: _vm.curExp,
              "expense-item": _vm.expenseItem,
              "expense-items": _vm.ExpItems,
              "is-my-expense": _vm.isMyExpense,
              "can-only-make-split-item": "",
            },
            on: {
              "expense-carousel-view-modal:closed":
                _vm.onExpenseCarouselViewClosed,
              "expense-carousel-view-modal:reload":
                _vm.onExpenseCarouselViewReloaded,
            },
            model: {
              value: _vm.isNewExpenseModalOpen,
              callback: function ($$v) {
                _vm.isNewExpenseModalOpen = $$v
              },
              expression: "isNewExpenseModalOpen",
            },
          }),
          _c("script-export-modal", {
            attrs: {
              items: _vm.scripts,
              custom: _vm.customReport,
              "export-type": "expense",
            },
            on: {
              "script-export-modal:loading": _vm.handleLoading,
              "script-export-modal:closed": _vm.expenseRerpotOnClosed,
            },
            model: {
              value: _vm.isScriptExpenseReportOpen,
              callback: function ($$v) {
                _vm.isScriptExpenseReportOpen = $$v
              },
              expression: "isScriptExpenseReportOpen",
            },
          }),
          _c(
            "b-modal",
            {
              staticClass: "modal-success",
              attrs: {
                "header-class": "header-class-modal-doc-package",
                title: _vm.FormMSG(21211, "Success!"),
                "ok-variant": "success",
                "ok-only": "",
              },
              on: {
                ok: function ($event) {
                  _vm.successModal = false
                },
              },
              model: {
                value: _vm.successModal,
                callback: function ($$v) {
                  _vm.successModal = $$v
                },
                expression: "successModal",
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.FormMSG(
                      6,
                      "The expense has been sent to your Email address"
                    )
                  ) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              staticClass: "modal-danger",
              attrs: {
                "header-class": "header-class-modal-doc-package",
                title: _vm.FormMSG(21212, "Expense item comment"),
                "ok-variant": "danger",
                "ok-only": "",
              },
              on: {
                ok: function ($event) {
                  _vm.modalExpenseItemComment = false
                },
              },
              model: {
                value: _vm.modalExpenseItemComment,
                callback: function ($$v) {
                  _vm.modalExpenseItemComment = $$v
                },
                expression: "modalExpenseItemComment",
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(51511, "Comment"),
                            "label-for": "comment",
                            "label-cols": 2,
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: { disabled: true, id: "comment", rows: "2" },
                            model: {
                              value: _vm.expenseItemComment,
                              callback: function ($$v) {
                                _vm.expenseItemComment = $$v
                              },
                              expression: "expenseItemComment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "info-message-modal",
        {
          attrs: { "show-modal": _vm.stateError.show },
          on: {
            close: function ($event) {
              _vm.stateError.show = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center text-info",
              staticStyle: { gap: "10px" },
            },
            [
              _c(_vm.getLucideIcon("Info"), {
                tag: "component",
                attrs: { size: 20 },
              }),
              _c("span", [_vm._v(_vm._s(_vm.stateError.msg))]),
            ],
            1
          ),
        ]
      ),
      _c("common-warning-on-delete", {
        attrs: { message: _vm.messageOnDeleteExpense },
        on: {
          "dialog:cancel": _vm.closeDeleteWarning,
          "dialog:confirm": _vm.deleteExpenseItem,
        },
        model: {
          value: _vm.showDeleteWarning,
          callback: function ($$v) {
            _vm.showDeleteWarning = $$v
          },
          expression: "showDeleteWarning",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }